export default function EmployeeSkeleton() {
  return (
    <div className="relative flex items-center space-x-3 bg-slate-900 px-6 py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-900 hover:bg-slate-800">
      <div className="flex-shrink-0">
        <div className="my-auto mr-1.5 h-14 w-14 rounded-full bg-slate-700" />
      </div>
      <div className="min-w-0 flex-1">
        <span className="absolute inset-0" aria-hidden="true" />
        <div className="my-auto flex pt-1">
          <div className="mr-2 h-1.5 w-14 rounded bg-slate-700"></div>
          <div className="h-1.5 w-12 rounded bg-slate-700"></div>
        </div>
        <div className="my-2 h-1.5 w-16 rounded bg-slate-700"></div>
      </div>
    </div>
  );
}
