/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { Get } from "../../api/sanity/Get";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { colors } from "../../utils/Utils";
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";
import sanityClient from "../../auth/Sanity";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import DeskBox from "components/desks/DeskBox";
import EmployeeCard from "components/molecules/EmployeeCard";

const Spot = ({ date, parking, index }) => {
  const changelogEntry = parking.log?.find(
    (log) => log.date === format(date, "yyyy-MM-dd", { locale: nb })
  );
  if (changelogEntry && changelogEntry?.reserved?.length > 0)
    return (
      <DeskBox className={colors[index]}>
        <EmployeeCard
          variant="xs"
          name={changelogEntry.reserved}
          className="m-auto p-0 text-black"
        />
      </DeskBox>
    );

  const isReserved = parking[format(date, "EEEE").toLowerCase()];
  if (isReserved && !changelogEntry)
    return (
      <DeskBox className={colors[index]}>
        <EmployeeCard
          variant="xs"
          name={isReserved}
          className="m-auto p-0 text-black"
        />
      </DeskBox>
    );

  return <DeskBox className={`${colors[index]} opacity-70 hover:opacity-80`} />;
};

const Parking = ({ dates, page, setPage }) => {
  const [refresh, setRefresh] = useState(false);
  const [parkings, setparkings] = useState();
  const { accounts } = useMsal();

  async function getparkings() {
    const request = '*[_type == "parking"]';
    const data = await Get(request);
    setparkings(data);
  }

  useEffect(() => {
    getparkings();
  }, [refresh]);

  const toggleBooking = (date, parking) => {
    const dateFormatted = format(date, "yyyy-MM-dd", { locale: nb });
    const changelogEntry = parking.log?.find(
      (log) => log.date === format(date, "yyyy-MM-dd", { locale: nb })
    );
    const isReserved = parking[format(date, "EEEE").toLowerCase()];

    let log = parking.log || [];

    log = log.filter((log) => log.date !== dateFormatted);
    log.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (entry[key] === null) {
          delete entry[key];
        }
      });
    });

    let query = sanityClient.patch(parking._id).set({ log });

    if (
      (changelogEntry &&
        (changelogEntry.reserved === accounts[0].name ||
          (changelogEntry?.reserved?.length > 0 &&
            (accounts[0].name === "Børre Zahl Jensen" ||
              accounts[0].name === "Mathias Iversen" ||
              accounts[0].name === "Joakim Obstfelder")))) ||
      (!changelogEntry &&
        isReserved &&
        (isReserved === accounts[0].name ||
          accounts[0].name === "Børre Zahl Jensen" ||
          accounts[0].name === "Mathias Iversen" ||
          accounts[0].name === "Joakim Obstfelder"))
    ) {
      // Unbook
      if (confirm("Gjør denne plassen tilgjengelig for andre?")) {
        return query
          .append("log", [{ _key: nanoid(), date: dateFormatted }])
          .commit()
          .then(() => setRefresh(!refresh))
          .catch(console.log);
      }
      return;
    }

    if (
      (!changelogEntry && isReserved) ||
      (changelogEntry && changelogEntry.reserved)
    ) {
      return null;
    }

    if (confirm("Reserver denne plassen?")) {
      query.append("log", [
        {
          _key: nanoid(),
          date: dateFormatted,
          reserved: accounts[0].name,
        },
      ]);
      return query
        .commit()
        .then(() => setRefresh(!refresh))
        .catch(console.log);
    }
  };

  if (parkings)
    return (
      <div className="mb-4 mt-8 flex w-full flex-col">
        <div className="flex">
          <p
            onClick={() => setPage(1)}
            className="mb-1 ml-1 mt-1 cursor-pointer text-2xl font-extralight text-slate-100"
          >
            Parkering
          </p>
          <ChevronLeftIcon
            className="my-auto ml-auto h-7 w-7 cursor-pointer text-white"
            onClick={() => setPage(page - 1)}
          />
          <ChevronRightIcon
            className="my-auto ml-2 h-7 w-7 cursor-pointer text-white"
            onClick={() => setPage(page + 1)}
          />
        </div>
        <div className="mb-2 flex w-full">
          <div className="w-8" />
          {dates.map((date) => (
            <p
              className="px-auto mx-auto text-white first-letter:uppercase"
              key={date}
            >
              {format(date, "EEEE d. MMM", { locale: nb })}
            </p>
          ))}
        </div>
        <div className="grid grid-flow-row auto-rows-max gap-2">
          {parkings
            .sort((parkingOne, parkingTwo) =>
              parkingOne.number > parkingTwo.number ? 1 : -1
            )
            .map((parking, index) => (
              <div className="flex" key={parking?._id}>
                <p className="my-auto ml-2 mr-5 w-2 text-white">
                  {parking.number}
                </p>
                {dates.map((date) => (
                  <div
                    className="mx-1 flex w-full cursor-default select-none rounded-lg hover:opacity-95"
                    key={parking._id + date.getDate()}
                    onClick={() => toggleBooking(date, parking)}
                  >
                    <Spot date={date} parking={parking} index={index} />
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    );

  return null;
};

export default Parking;
