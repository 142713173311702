import BaseComponent from "./BaseComponent";

/** Paragraph
 * Basic paragraph
 * 
 * @returns ReactNode
 */
export default function Paragraph({className = '', as='p', ...rest}) {
    className = `text-white mb-2 max-w-lg ${className}`;

    return <BaseComponent {...rest} className={className} as={as} />
}