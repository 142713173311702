import { useEffect, useState } from 'react';
import { Get } from '../../api/sanity/Get';
import { colors } from '../../utils/Utils';
import { useSearchContext } from '../search/useSearchContext';
import { Card } from './Card';

export const Wiki = () => {
  const [data, setData] = useState([]);
  const { isSearching } = useSearchContext();

  const getPosts = async () => {
    const request = '*[_type == "article"] | order(publishedAt desc)';
    return await Get(request);
  }

  useEffect(() => {
    getPosts().then(data => setData(data));
  }, []);

  const topics = [...new Set(data.map((e) => e.topic))];

  if (data && !isSearching) {
    return (
      <>
        {topics.map((topic, index) => (
          <div key={topic} className="flex flex-col px-4 py-4">
            <h2 className="text-2xl font-extralight text-slate-100 flex-1 mb-4 ml-4">
              {topic}
            </h2>
            <nav className="grid gap-4 grid-fluid-72">
              {data
                .filter((element) => {
                  if (topic === element.topic) {
                    return element;
                  } else {
                    return null;
                  }
                })
                .map((element) => (
                  <Card key={element._id} data={element} backgroundColor={colors[index]}/>
                ))}
            </nav>
          </div>
        ))}
      </>
    );
  }

  return null;
};
