import BaseComponent from "./BaseComponent";

/** H1
 * Main header - text-2xl font-extralight
 * 
 * @param as string | ReactComponent (default: "h1") Which DOM/React component to render as
 * 
 * @example
 *   <H1 as="h2">h2 with h1 styling</H1>
 * 
 * @returns ReactNode
 */
export default function H1({className = '', as='h1', ...rest}) {
    className = `mb-1 ml-1 mt-4 text-2xl font-extralight text-slate-100 ${className}`;

    return <BaseComponent {...rest} className={className} as={as} />
}