import { Hits } from '../../components/wiki/Hits';
import { Search as SearchComponent } from '../../components/search/Search';
import { SearchProvider } from '../../components/search/SearchProvider';
import { Wiki as WikiComponent } from '../../components/wiki/Wiki';
import { sanityDataset } from 'utils/Utils';

const Wiki = () => {
  return (
    <SearchProvider>
      <SearchComponent
        indexName={`${sanityDataset()}_wiki`}
        placeholder="Søk i wiki"
        title="Wiki"
        HitsComponent={Hits}
      />
      <WikiComponent />
    </SearchProvider>
  );
};

export default Wiki;
