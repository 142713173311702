import { XMarkIcon } from '@heroicons/react/24/solid';
import { useRef, useState } from 'react';
import { useSearchBox } from 'react-instantsearch';

export const SearchBox = ({ queryHook, placeholder }) => {
  const { query, refine, clear } = useSearchBox({ queryHook });
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef(null);

  const setQuery = newQuery => {
    setInputValue(newQuery);
    refine(newQuery);
  }

  const onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    if (inputRef.current) {
      inputRef.current.blur();
    }
  }

  const onReset = event => {
    event.preventDefault();
    event.stopPropagation();

    setQuery("");

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  const onChange = event => {
    setQuery(event.currentTarget.value);
  }

  return (
    <form
      action=""
      role="search"
      noValidate
      onSubmit={onSubmit}
      onReset={onReset}
      className="flex rounded-full w-min-lg bg-gray-700 items-center"
    >
      <input
        ref={inputRef}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        placeholder={placeholder}
        spellCheck={false}
        maxLength={512}
        value={inputValue}
        onChange={onChange}
        type="search"
        className="grow rounded-full px-4 py-3 bg-gray-600 text-white hover:bg-gray-500 focus:bg-gray-100
        focus:text-black placeholder:text-gray-400 hover:placeholder:text-gray-300 placeholder:focus:text-gray-800
        border-none"
      />
      <button
        className={`rounded-full bg-gray-600 text-white h-7 w-7 mx-2 p-1 ${!inputValue && "hidden"}`}
        onClick={onReset}
        type="button"
      >
        <XMarkIcon/>
      </button>
    </form>
  );
}
