export async function Delete(token, endpoint) {
  try {
    const response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("HTTP error " + response.status);
    }
  } catch (error) {
    console.error(error);
  }
}