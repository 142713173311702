import { InteractionType } from "@azure/msal-browser";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { TokenProvider } from "providers/TokenProvider";
import "./index.css";
import { loginRequest } from "./auth/Microsoft";
import { Navigation } from "./shells";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import {
  News,
  Calendar,
  Booking,
  Wiki,
  Employees,
  Post,
  Notice,
  Deviation,
} from "./views";

const App = () => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <TokenProvider>
        <Navigation>
          <Routes>
            <Route path="/" exact element={<News />} />
            <Route path="/calendar" exact element={<Calendar />} />
            <Route
              path="/id/950821e7-4198-49b0-8001-bc92f436d39f"
              element={<Notice />}
            />
            <Route path="/notice" element={<Notice />} />
            <Route path="/deviation" element={<Deviation />} />
            <Route
              path="/id/f694d588-f4f9-45e3-97b8-8a7fbd993e04"
              element={<Deviation />}
            />
            <Route path="/booking" element={<Booking />} />
            <Route path="/wiki" element={<Wiki />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/notice" element={<Notice />} />
            <Route path="/deviation" element={<Deviation />} />
            <Route path="/id/*" element={<Post />} />
            <Route path="*" element={<News />} />
          </Routes>
        </Navigation>
      </TokenProvider>
    </MsalAuthenticationTemplate>
  );
};

export default App;
