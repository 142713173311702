/* eslint-disable no-restricted-globals */
import { useEffect, useState, useContext } from "react";
import { Get } from "../../api/sanity/Get";
import { Post } from "../../api/microsoft/Post";
import { format } from "date-fns";
import { nb } from "date-fns/locale";
import { colors } from "../../utils/Utils";
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";
import sanityClient from "../../auth/Sanity";
import { graphConfig } from "../../auth/Microsoft";
import { Get as MSGet } from "../../api/microsoft/Get";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ComputerDesktopIcon,
  EnvelopeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { TOKEN } from "../../providers/TokenProvider";
import { Dialog } from "@headlessui/react";
import WorkPlaceLayout from "../../assets/images/WorkPlaceLayout.png";
import EmployeeCard from "components/molecules/EmployeeCard";
import DeskBox from "./DeskBox";

function WorkPlacelayout({ openWorkPlacelayout, setOpenWorkPlacelayout }) {
  return (
    <Dialog
      open={openWorkPlacelayout}
      onClose={() => setOpenWorkPlacelayout(false)}
      className="relative z-50"
    >
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-6xl rounded bg-white">
          <button />
          <img src={WorkPlaceLayout} alt="WorkPlaceLayout" />
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

async function SendTeamsMessage(context, bookedUsers, message) {
  let answer = window.confirm("Send message?");
  if (answer) {
    const MSEmployees = await MSGet(context, graphConfig.graphUsersEndpoint);
    const me = await MSGet(context, graphConfig.graphMeEndpoint);

    bookedUsers.forEach(async (user) => {
      let foundUser = MSEmployees?.value?.find((x) => x.displayName === user);

      if (foundUser?.mail) {
        const createChat = await Post(context, graphConfig.graphChatEndpoint, {
          chatType: "oneOnOne",
          members: [
            {
              "@odata.type": "#microsoft.graph.aadUserConversationMember",
              roles: ["owner"],
              "user@odata.bind": `https://graph.microsoft.com/v1.0/users('${me?.mail.toString()}')`,
            },
            {
              "@odata.type": "#microsoft.graph.aadUserConversationMember",
              roles: ["owner"],
              "user@odata.bind": `https://graph.microsoft.com/v1.0/users('${foundUser?.mail?.toString()}')`,
            },
          ],
        });

        const id = createChat?.id;

        await Post(
          context,
          `https://graph.microsoft.com/v1.0/chats/${id}/messages`,
          {
            body: {
              content: `${message}`,
            },
          }
        );
      }
    });
  }
}

const Desk = ({ date, desk, index }) => {
  const changelogEntry = desk.log?.find(
    (log) => log.date === format(date, "yyyy-MM-dd", { locale: nb })
  );
  if (changelogEntry && changelogEntry?.reserved?.length > 0)
    return (
      <DeskBox className={colors[index]}>
        <EmployeeCard
          variant="xs"
          name={changelogEntry.reserved}
          className="m-auto p-0 text-black"
        />
      </DeskBox>
    );

  const isReserved = desk[format(date, "EEEE").toLowerCase()];
  if (isReserved && !changelogEntry)
    return (
      <DeskBox className={colors[index]}>
        <EmployeeCard
          variant="xs"
          name={isReserved}
          className="m-auto p-0 text-black"
        />
      </DeskBox>
    );

  return <DeskBox className={`${colors[index]} opacity-70 hover:opacity-80`} />;
};

const Desks = ({ dates, page, setPage }) => {
  const [refresh, setRefresh] = useState(false);
  const [desks, setDesks] = useState();
  const { accounts } = useMsal();
  const [openWorkPlacelayout, setOpenWorkPlacelayout] = useState(false);
  const [messagePopUp, setMessagePopUp] = useState(false);
  const [teamsMessage, setTeamsMessage] = useState("");
  const [emailList, setEmailList] = useState([]);
  const context = useContext(TOKEN);

  const getBookedUsers = (data) => {
    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const today = new Date();
    const dayName = daysOfWeek[today.getDay()];
    const formattedDate = today.toISOString().split("T")[0];
    const reservedToday = [];

    data.forEach((desk) => {
      if (dayName in desk) {
        reservedToday.push(desk[dayName]);
      }
      desk.log?.forEach((el) => {
        if (el.date === formattedDate) {
          if (el.reserved) {
            reservedToday.push(el.reserved);
          } else {
            reservedToday.pop();
          }
        }
      });
    });
    return [...new Set(reservedToday)];
  };

  async function getdesks() {
    const request = '*[_type == "desk"]';
    const data = await Get(request);
    const distinctUserList = getBookedUsers(data);
    setEmailList(distinctUserList);
    setDesks(data);
  }

  useEffect(() => {
    getdesks();
    // eslint-disable-next-line
  }, [refresh]);

  const toggleBooking = (date, desk) => {
    const dateFormatted = format(date, "yyyy-MM-dd", { locale: nb });
    const changelogEntry = desk.log?.find(
      (log) => log.date === format(date, "yyyy-MM-dd", { locale: nb })
    );
    const isReserved = desk[format(date, "EEEE").toLowerCase()];

    let log = desk.log || [];

    log = log.filter((log) => log.date !== dateFormatted);
    log.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (entry[key] === null) {
          delete entry[key];
        }
      });
    });

    let query = sanityClient.patch(desk._id).set({ log });

    if (
      (changelogEntry &&
        (changelogEntry.reserved === accounts[0].name ||
          (changelogEntry?.reserved?.length > 0 &&
            (accounts[0].name === "Børre Zahl Jensen" ||
              accounts[0].name === "Mathias Iversen" ||
              accounts[0].name === "Joakim Obstfelder")))) ||
      (!changelogEntry &&
        isReserved &&
        (isReserved === accounts[0].name ||
          accounts[0].name === "Børre Zahl Jensen" ||
          accounts[0].name === "Mathias Iversen" ||
          accounts[0].name === "Joakim Obstfelder"))
    ) {
      // Unbook
      if (confirm("Gjør denne plassen tilgjengelig for andre?")) {
        return query
          .append("log", [{ _key: nanoid(), date: dateFormatted }])
          .commit()
          .then(() => setRefresh(!refresh))
          .catch(console.log);
      }
      return;
    }

    if (
      (!changelogEntry && isReserved) ||
      (changelogEntry && changelogEntry.reserved)
    ) {
      return null;
    }

    if (
      confirm(
        "Reserver plass " +
          desk.number +
          " den " +
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "?"
      )
    ) {
      query.append("log", [
        {
          _key: nanoid(),
          date: dateFormatted,
          reserved: accounts[0].name,
        },
      ]);
      return query
        .commit()
        .then(() => setRefresh(!refresh))
        .catch(console.log);
    }
  };

  if (desks)
    return (
      <div className="flex w-full flex-col">
        <div className="flex">
          <p
            onClick={() => setPage(1)}
            className="mb-1 ml-1 mt-1 cursor-pointer text-2xl font-extralight text-slate-100"
          >
            Pulter
          </p>
          <ChevronLeftIcon
            className="my-auto ml-auto h-7 w-7 cursor-pointer text-white"
            onClick={() => setPage(page - 1)}
          />
          <ChevronRightIcon
            className="my-auto ml-2 h-7 w-7 cursor-pointer text-white"
            onClick={() => setPage(page + 1)}
          />
          <ComputerDesktopIcon
            className="my-auto ml-2 h-7 w-7 cursor-pointer text-white hover:opacity-80"
            onClick={() => setOpenWorkPlacelayout(!openWorkPlacelayout)}
          />
          <EnvelopeIcon
            className="my-auto ml-2 h-8 w-8 cursor-pointer text-white hover:opacity-80"
            onClick={() => setMessagePopUp(!messagePopUp)}
          />
          {messagePopUp ? (
            <Dialog
              open={messagePopUp}
              onClose={() => setMessagePopUp(false)}
              className="relative z-50"
            >
              <div className="fixed inset-0 flex w-full items-center justify-center p-4">
                <Dialog.Panel className="h-fit w-fit max-w-xs rounded-xl border border-slate-600 bg-slate-800 p-4 lg:max-w-2xl">
                  <div className="flex">
                    <label className="text-white">Teams Mesasge</label>
                    <XMarkIcon
                      className="ml-auto h-6 w-6 cursor-pointer text-slate-600 hover:opacity-80"
                      onClick={() => setMessagePopUp(!messagePopUp)}
                    />
                  </div>
                  <p className="mb-2 text-xs text-slate-400">
                    Send a message to all employees that have booked a desk
                    today.
                  </p>
                  <div className="flex flex-col">
                    <textarea
                      rows={4}
                      name="teamsMessage"
                      id="teamsMessage"
                      value={teamsMessage}
                      onChange={(e) => setTeamsMessage(e.target.value)}
                      className="box-border h-20 w-full rounded bg-slate-700 text-white"
                    />
                    <button
                      type="button"
                      className="mt-4 w-20 rounded bg-blue-600 px-2 py-1 font-medium text-white hover:bg-blue-700"
                      onClick={() => {
                        SendTeamsMessage(context, emailList, teamsMessage);
                        setMessagePopUp(!messagePopUp);
                      }}
                    >
                      Send
                    </button>
                  </div>
                </Dialog.Panel>
              </div>
            </Dialog>
          ) : null}
        </div>
        <div className="mb-2 flex w-full">
          <div className="w-8" />
          {dates.map((date) => (
            <p
              className="px-auto mx-auto text-white first-letter:uppercase"
              key={date}
            >
              {format(date, "EEEE d. MMM", { locale: nb })}
            </p>
          ))}
        </div>
        <div className="grid grid-flow-row auto-rows-max gap-2">
          {desks
            .sort((deskOne, deskTwo) =>
              deskOne.number > deskTwo.number ? 1 : -1
            )
            .map((desk, index) => (
              <div className="flex" key={desk?._id}>
                <p className="my-auto ml-2 mr-5 w-2 text-white">
                  {desk.number}
                </p>
                {dates.map((date) => (
                  <div
                    className="mx-1 flex w-full cursor-default select-none rounded-lg hover:opacity-95"
                    key={desk._id + date.getDate()}
                    onClick={() => toggleBooking(date, desk)}
                  >
                    <Desk date={date} desk={desk} index={index} />
                  </div>
                ))}
              </div>
            ))}
        </div>
        <WorkPlacelayout
          openWorkPlacelayout={openWorkPlacelayout}
          setOpenWorkPlacelayout={setOpenWorkPlacelayout}
        />
      </div>
    );

  return null;
};

export default Desks;
