import { NewsComponent } from "components";
import { Hits } from "../../components/news/Hits";
import { Search as SearchComponent } from "../../components/search/Search";
import { SearchProvider } from "../../components/search/SearchProvider";
import { sanityDataset } from "utils/Utils";

const News = () => {
  return (
    <SearchProvider>
      <SearchComponent
        indexName={`${sanityDataset()}_news`}
        placeholder="Søk i nyheter"
        title="News"
        HitsComponent={Hits}
      />
      <NewsComponent />
    </SearchProvider>
  );
};

export default News;
