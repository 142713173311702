import React, { useEffect, useState } from "react";
import { Desks, Parking } from "../../components";

const Booking = () => {
  const [pageSize, setPageSize] = useState(6);
  const [page, setPage] = useState(1);
  const [dates, setDates] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const getDaysArray = (start, end) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setPageSize(width <= 1024 ? 2 : 6);
  }, [width]);

  useEffect(() => {
    let startDate = new Date(Date());
    startDate.setDate(startDate.getDate() + pageSize * (page - 1));
    let endDate = new Date(Date());
    endDate.setDate(endDate.getDate() + pageSize * page);
    setDates(getDaysArray(startDate, endDate));
  }, [page, pageSize]);

  return (
    <div className="px-4 py-2">
      <Desks dates={dates} page={page} setPage={setPage} />
      <Parking dates={dates} page={page} setPage={setPage} />
    </div>
  );
};

export default Booking;
