import { graphConfig } from "../../auth/Microsoft";
import { Get as MSGet } from "../../api/microsoft/Get";
import { Post } from "../../api/microsoft/Post";

export async function SendTeamsMessage(context, users, message) {

  let answer = window.confirm("Send message?");
  try {
    if (answer) {
      const me = await MSGet(context, graphConfig.graphMeEndpoint);
      users.forEach(async (user) => {
        if (user?.mail) {
          const createChat = await Post(context, graphConfig.graphChatEndpoint, {
            chatType: "oneOnOne",
            members: [
              {
                "@odata.type": "#microsoft.graph.aadUserConversationMember",
                roles: ["owner"],
                "user@odata.bind": `https://graph.microsoft.com/v1.0/users('${me?.mail.toString()}')`,
              },
              {
                "@odata.type": "#microsoft.graph.aadUserConversationMember",
                roles: ["owner"],
                "user@odata.bind": `https://graph.microsoft.com/v1.0/users('${user?.mail?.toString()}')`,
              },
            ],
          });
  
          const id = createChat?.id;
  
          await Post(
            context,
            `https://graph.microsoft.com/v1.0/chats/${id}/messages`,
            {
              body: {
                contentType: "html",
                content: `${message}`,
              },
            }
          );
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
  
}