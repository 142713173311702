/** BaseComponent
 *
 * @param props.as {string | React.ComponentType} (default: 'div') What to render as
 * 
 * @example 
 *   <BaseComponent as={Paragraph}>Children</BaseComponent>
 * @example
 *   <BaseComponent as="p">A paragraph</BaseComponent>
*/
export default function BaseComponent({
  children,
  as = "div",

  // Remove sanity stuff from DOM output
  node,
  value,
  isInline,
  renderNode,
  markKey,
  markType,

  // Extract and pass rest to DOM
  ...rest
}) {
  const Element = as;

  return <Element {...rest}>{children}</Element>;
}
