import { NavLink } from 'react-router-dom';

export const Card = ({ data, backgroundColor }) => {
  return (
    <article className={`h-44 flex flex-col rounded-3xl hover:opacity-90 ${backgroundColor}`}>
      <NavLink
        to={`/id/${data._id}`}
        className="h-full flex flex-col-reverse justify-end p-4 rounded-3xl"
      >
        <h3 className="text-gray-900 text-xl line-clamp-3 text-ellipsis break-words hyphens-auto">
          {data.title}
        </h3>
        <h4 className="text-gray-600 text-xs">
          {data.topic}
        </h4>
      </NavLink>
    </article>
  );
};
