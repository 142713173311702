export async function Post(token, endpoint, payload) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");
  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  };

  return new Promise((resolve, reject) =>
    fetch(endpoint, options)
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  );
}
