import { useMsal } from '@azure/msal-react';
import { useHits } from 'react-instantsearch';
import { Card } from './Card';
import { useEffect, useState } from 'react';
import { Get } from "../../api/sanity/Get";

export const Hits = () => {
  const { hits, results } = useHits(null);
  const { accounts } = useMsal();
  const [seenByUser, setSeenByUser] = useState(null);

  const query = `*[_type == "post" && "${accounts[0].localAccountId}" in seenBy[].oid]._id`;

  const rewriteData = (hit) => {
    const seenByResolved = seenByUser? true : false;
    const response = {
      ...hit,
      _id: hit.objectID,
      seenBy: (seenByResolved && seenByUser.includes(hit.objectID)) ? [{_key: null, name: accounts[0].name, oid: accounts[0].localAccountId}] : [],
      };
    return response;
  };


  useEffect(() => {
    let ignore = false;

    const getSeenByUser = async () => {
      await Get(query).then((response) => {
        setSeenByUser(response);
      });
    };

    if (!ignore) getSeenByUser();

    return () => {
      ignore = true;
    };
    
  }, []);

  return (
    <div className="px-4 py-4">
      <div className="flex items-baseline gap-8 mb-4 ml-4">
        <h1 className="text-2xl font-extralight text-slate-100 flex-1">
          News
        </h1>
        <h2 className="text-2xl text-slate-100 w-min-lg">
          {hits.length} Treff
        </h2>
        <div className="flex-1" />
      </div>
      <nav className="grid gap-4 grid-fluid-72">
        {hits.map(hit => (
          <Card key={hit.objectID} data={rewriteData(hit)} />
        ))}
      </nav>
    </div>
  );
};
