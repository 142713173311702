import { NavLink } from 'react-router-dom';
import { toDisplayDayMonthYear } from '../../utils/Utils';
import { useMsal } from '@azure/msal-react';

export const Card = ({ data }) => {
  const { accounts } = useMsal();

  const isSeenByUser = data.seenBy?.some(
    (user) => user.oid === accounts[0].localAccountId
  );

  return (
    <article className="flex h-44 flex-col rounded-3xl bg-cyan-200 hover:opacity-90">
      <NavLink
        to={`/id/${data._id}`}
        className="relative flex h-full flex-col rounded-3xl p-4"
      >
        {!isSeenByUser && (
          <>
            <div className="absolute bottom-4 right-4 h-3 w-3 animate-ping rounded-full bg-red-500" />
            <div className="absolute bottom-4 right-4 h-3 w-3 rounded-full bg-red-500" />
          </>
        )}
        <div className="flex grow flex-col-reverse justify-end">
          <h3 className="line-clamp-3 text-ellipsis hyphens-auto break-words text-xl text-gray-900">
            {data.title}
          </h3>
          <h4 className="text-xs text-gray-600">
            {data._createdAt && toDisplayDayMonthYear(data._createdAt)}
          </h4>
        </div>
        <address className="text-gray-900">{data.author || ''}</address>
      </NavLink>
    </article>
  );
};
