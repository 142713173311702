import { useEffect, useState } from "react";
import { Get } from "../../api/sanity/Get";
import { useSearchContext } from "../search/useSearchContext";
import { Card } from "./Card";

const News = () => {
  const [data, setData] = useState([]);
  const { isSearching } = useSearchContext();

  const getPosts = async () => {
    const request = '*[_type == "post"] | order(_createdAt desc)[0...8]';
    return await Get(request);
  };

  useEffect(() => {
    getPosts().then((data) => setData(data));
  }, []);

  if (data && !isSearching) {
    return (
      <div className="flex flex-col px-4 py-4">
        <h1 className="mb-4 ml-4 flex-1 text-2xl font-extralight text-slate-100">
          Nyheter
        </h1>
        <nav className="grid-fluid-72 grid gap-4">
          {data.map((element) => (
            <Card key={element._id} data={element} />
          ))}
        </nav>
      </div>
    );
  }

  return null;
};

export default News;
