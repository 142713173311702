import { Events } from "components";
import { Hits } from '../../components/events/Hits';
import { Search as SearchComponent } from '../../components/search/Search';
import { SearchProvider } from '../../components/search/SearchProvider';
import { sanityDataset } from "utils/Utils";

const Calendar = () => {
  return (
    <SearchProvider>
      <SearchComponent
        indexName={`${sanityDataset()}_calendar`}
        placeholder="Søk i kalender"
        title="Events"
        HitsComponent={Hits}
      />
      <Events />
    </SearchProvider>
  );
};

export default Calendar;
