import { Fragment, useContext, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utils/Utils";
import { GetBlob } from "../../api/microsoft/GetBlob";
import { TOKEN } from "../../providers/TokenProvider";
import { graphConfig } from "../../auth/Microsoft";
import { useMsal } from "@azure/msal-react";
import EmployeeCard from "components/molecules/EmployeeCard";

const userNavigation = [{ name: "Sign out", href: "#" }];

const User = () => {
  const { accounts } = useMsal();
  const context = useContext(TOKEN);
  const [photo, setPhoto] = useState();

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [context]);

  async function getUser() {
    if (context !== "") {
      const data = await GetBlob(context, graphConfig.graphMePhotoEndpoint);
      setPhoto(URL.createObjectURL(data));
    }
  }
  if (accounts && accounts)
    return (
      <>
        <Menu as="div" className="relative">
          <Menu.Button className="flex flex-row items-center p-1.5">
            <span className="sr-only">Open user menu</span>
            <EmployeeCard
              avatar={photo}
              name={accounts[0].name}
              variant="small"
              aria-hidden="true"
              className="px-0 font-bold"
            >
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </EmployeeCard>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-slate-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={classNames(
                        active ? "bg-slate-700" : "",
                        "block px-3 py-1 text-sm leading-6 text-slate-200"
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );
};

export default User;
