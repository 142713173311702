import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { createContext, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from "../auth/Microsoft";

export const TOKEN = createContext("");

export const TokenProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState("");
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const request = { ...loginRequest, account };

  instance.acquireTokenSilent(request).then(response => {
    setAccessToken(response.accessToken);
  }).catch(error => {
    if (error instanceof InteractionRequiredAuthError) {
      return instance.acquireTokenRedirect(request);
    }

    throw error;
  });

  return <TOKEN.Provider value={accessToken}>{children}</TOKEN.Provider>;
};
