import { InstantSearch } from 'react-instantsearch';
import { searchClient } from '../../auth/Algolia';
import { SearchBox } from './SearchBox';
import { useSearchContext } from './useSearchContext';

const timeout = 400;
let timerId = undefined;

export const Search = ({ indexName, placeholder, HitsComponent }) => {
  const { isSearching, setIsSearching } = useSearchContext();

  const queryHook = (query, search) => {
    if (isSearching !== !!query) {
      setIsSearching(!!query);
    }

    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => search(query), timeout);
  };

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <div className="flex flex-col">
        <div className="flex border-b border-slate-800 py-4 px-4 justify-center">
          <SearchBox queryHook={queryHook} placeholder={placeholder} />
        </div>
        {isSearching && <HitsComponent />}
      </div>
    </InstantSearch>
  );
};
