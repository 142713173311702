import { PortableText } from "@portabletext/react";
import urlBuilder from "@sanity/image-url";
import sanityClient from "../../auth/Sanity";
import { Paragraph, A } from "components/atoms";

const ImageComponent = ({ value, isInline }) => {
  return (
    <img
      src={urlBuilder(sanityClient)
        .image(value)
        .width(isInline ? 100 : 800)
        .fit("max")
        .auto("format")
        .url()}
      alt={value.alt || " "}
      loading="lazy"
      style={{
        display: isInline ? "inline-block" : "block",
      }}
    />
  );
};

const components = {
  types: {
    image: ImageComponent,
    link: A
  },
  marks: {
    a: A,
    link: A
  },
  block: {
    normal: Paragraph
  }
};

const BlockContent = ({ value }) => {
  return <PortableText value={value} components={components} />;
};

export default BlockContent;
