import { useContext } from 'react';
import { SearchContext } from './SearchProvider';

export const useSearchContext = () => {
  const searchContext = useContext(SearchContext);

  if (!searchContext) {
    throw new Error("`useSearchContext` must be rendered in a descendant of `SearchProvider`.");
  }

  return searchContext;
};
