export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function isDate14DaysAgo(date) {
  const today = new Date();
  const thirtyDaysAgo = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000); // Calculate the date 30 days ago
  return date <= thirtyDaysAgo;
}

export const toDisplayDayMonthYear = (dateString) => {
  return new Date(dateString).toLocaleDateString("nb",
      {day: 'numeric', month: 'numeric', year: 'numeric', weekday: 'long'}).toUpperCase();
}

export const sanityDataset = () => {
  return (process.env.NODE_ENV === "development" || process.env.REACT_APP_DATASET === "dev" || process.env.REACT_APP_DATASET === "test") ? "test" : "production";
}

export function toDisplayTime(dateString) {
  let date = new Date(dateString);
  let time = date.toLocaleTimeString("nb", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return `${time}`;
}

export const colors = [
  "bg-cyan-200",
  "bg-yellow-300",
  "bg-zinc-200",
  "bg-emerald-300",
  "bg-rose-300",
  "bg-violet-300",
  "bg-amber-300",
  "bg-sky-300",
  "bg-lime-300",
  "bg-fuchsia-300",
  "bg-slate-300",
  "bg-teal-300",
  "bg-cyan-200",
  "bg-yellow-300",
  "bg-zinc-200",
  "bg-emerald-300",
  "bg-rose-300",
  "bg-violet-300",
  "bg-amber-300",
  "bg-sky-300",
  "bg-lime-300",
  "bg-fuchsia-300",
  "bg-slate-300",
  "bg-teal-300",
  "bg-cyan-200",
  "bg-yellow-300",
  "bg-zinc-200",
  "bg-emerald-300",
  "bg-rose-300",
  "bg-violet-300",
  "bg-amber-300",
  "bg-sky-300",
  "bg-lime-300",
  "bg-fuchsia-300",
  "bg-slate-300",
  "bg-teal-300",
  "bg-cyan-200",
  "bg-yellow-300",
  "bg-zinc-200",
  "bg-emerald-300",
  "bg-rose-300",
  "bg-violet-300",
  "bg-amber-300",
  "bg-sky-300",
  "bg-lime-300",
  "bg-fuchsia-300",
  "bg-slate-300",
  "bg-teal-300",
];
