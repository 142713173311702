/** groupBy
 * Simple group by util
 * 
 * @param {Array} list An array of things to group
 * @param {(item) => string} keyGetter Function for getting the group of an item of the array
 * @returns {Object}
 */
export default function groupBy(list, keyGetter) {
  const map = {};
  list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map[key];
        if (!collection) {
          map[key] = [item];
        } else {
          collection.push(item);
        }
  });
  return map;
}