import { createClient } from "@sanity/client";
import { sanityDataset } from "utils/Utils";

export default createClient({
  projectId: "5o8cpdaf",
  dataset: sanityDataset(),
  token:
    "skcPSNddgBaEKhJtRBzB8cJllcrXvNDzjfKVsDkC8KGzA7GMtEca2oLrbuxQ2oHbiC8GtzxKCBQ4norcnMALx5ZnuYrqWKJS1Jd4r93wWPEwSzSd26aC1vQqTdwKxWdtBsx5begzicl3aIpn6A9ypMyOh9CtTRCFfnjtWVUxZo8ayIGQglLN",
  apiVersion: "2021-04-20",
  useCdn: false,
  ignoreBrowserTokenWarning: true,
  perspective: "published"
});
