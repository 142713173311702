import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../../utils/Utils";
// Icons
import { Square2StackIcon } from "@heroicons/react/24/outline";
import EmployeeSkeleton from "./EmployeeSkeleton";
import ADEmployeeCard from "components/molecules/ADEmployeeCard";

export default function Employee({ user, skeleton = false, index }) {
  if (skeleton) {
    return <EmployeeSkeleton />;
  }

  return (
    <Menu key={user.id}>
      <ADEmployeeCard as={Menu.Button} variant="medium" user={user} />

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="w-full divide-y divide-slate-700 border border-slate-700 bg-slate-800 hover:outline-none hover:ring-1 hover:ring-slate-900 focus:outline-none focus:ring-1 focus:ring-slate-900">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(user?.mobilePhone);
                  }}
                  className={classNames(
                    active ? "bg-slate-700" : "",
                    "flex cursor-pointer py-3 pl-4 pr-2 text-sm text-slate-200"
                  )}
                >
                  <p className="my-auto mr-4 text-xs text-slate-200">
                    {user?.mobilePhone}
                  </p>
                  <Square2StackIcon className="my-auto ml-auto h-4 w-4 text-slate-500" />
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(user?.mail);
                  }}
                  className={classNames(
                    active ? "bg-slate-700" : "",
                    "flex cursor-pointer py-3 pl-4 pr-2 text-sm text-slate-200"
                  )}
                >
                  <p className="my-auto mr-4 text-xs text-slate-200">
                    {user?.mail}
                  </p>
                  <Square2StackIcon className="my-auto ml-auto h-4 w-4 text-slate-500" />
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
