import { useContext, useEffect, useState } from "react";
import { graphConfig } from "../../auth/Microsoft";
import { Get } from "../../api/microsoft/Get";
import { TOKEN } from "../../providers/TokenProvider";
import Employee from "./Employee";
import groupBy from "utils/groupBy";
import getandMergeEmployeeAssignments from "utils/getandMergeEmployeeAssignments";
import FilterInput from "components/molecules/FilterInput";
import EmployeeLetterHeader from "./EmployeeLetterHeader";

async function getEmployees(token) {
  if (token !== "") {
    const data = await Get(token, graphConfig.graphUsersEndpoint);
    const employees = await getandMergeEmployeeAssignments(token, data.value);
    return employees;
  }
}

const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const Employees = () => {
  const token = useContext(TOKEN);
  const [users, setUsers] = useState();
  const [filteredUsers, setFilteredUsers] = useState();
  const [filter, setFilter] = useState("");

  const handleResetFilter = () => setFilter("");
  const handleFilterUsers = (newFilter) => setFilter(newFilter);

  useEffect(() => {
    if (filter === "") {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(() =>
        users.filter((u) =>
          `${u.givenName} ${u.surName} ${u.assignment} ${u.jobTitle}`
            .toLowerCase()
            .includes(filter.toLowerCase())
        )
      );
    }
  }, [users, filter]);

  useEffect(() => {
    getEmployees(token).then(setUsers);
  }, [token]);

  if (filteredUsers) {
    const grouped = groupBy(filteredUsers, (u) => u.displayName[0]);
    const alphabet = Object.keys(grouped).sort();

    return (
      <nav className="mx-auto h-full max-w-xl overflow-y-auto">
        <FilterInput
          className="my-4"
          onChange={handleFilterUsers}
          onReset={handleResetFilter}
          filter={filter}
          inputTitle="Filtrer Rainmakers"
          inputPlaceholder="Filtrer Rainmakers"
          resetTitle="Nullstill ansatt-filter"
        />

        {alphabet.map((letter) => (
          <div key={letter}>
            <EmployeeLetterHeader>
              <h3>{letter}</h3>
            </EmployeeLetterHeader>
            <ul className="divide-y divide-slate-800">
              {grouped[letter].map((user, index) => (
                <Employee key={user.id} user={user} index={index} />
              ))}
            </ul>
          </div>
        ))}
      </nav>
    );
  }

  return (
    <nav className="mx-auto h-full max-w-xl animate-pulse overflow-y-auto">
      <div className="border-1 my-4 flex h-10 flex-row rounded-full border-gray-100 bg-gray-700 text-white"></div>
      {letters.map((letter) => (
        <div key={letter}>
          <EmployeeLetterHeader>
            <h3>{letter}</h3>
          </EmployeeLetterHeader>
          <ul className="divide-y divide-slate-800">
            <li>
              <Employee skeleton />
            </li>
            <li>
              <Employee skeleton />
            </li>
          </ul>
        </div>
      ))}
    </nav>
  );
};

export default Employees;
