import sanityClient from "./../../auth/Sanity";

export async function Get(request) {
  return new Promise((resolve, reject) =>
    sanityClient
      .fetch(request)
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  );
}
