import { BaseComponent } from "components/atoms";

export default function DeskBox({ as = "div", className = "", ...rest }) {
  const innerClassNames =
    "flex h-8 w-full rounded-lg p-1 text-sm lg:h-10 lg:p-2 lg:text-base";
  return (
    <BaseComponent
      className={`${innerClassNames} ${className}`}
      as={as}
      {...rest}
    />
  );
}
