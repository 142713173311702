import BaseComponent from "./BaseComponent";

const variants = {
    default: "text-sky-500 hover:text-sky-700 underline"
}

/** A
 * 
 * @param as {string | ReactComponent} (default: a) what DOM element or React component to render as
 * @param variant {string} (default: default) Variants: default
 * 
 * @example
 *   <A variant="default" href="url">I'm a link</A>
 * 
 * @returns ReactNode
 */
export default function A({className = '', as='a', variant="default", ...rest}) {
    const variantClasses = variants[variant] ?? variants["default"];

    // Unpack Sanity stuff
    if(rest.value && rest.value.href) {
        rest.href = rest.value.href;
    }

    className = `${variantClasses} ${className}`;

    return <BaseComponent {...rest} className={className} as={as} />
}