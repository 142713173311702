import { Avatar, BaseComponent } from "../atoms";

/** EmployeeCard
 * Card for displaying a single employee's information
 *
 * @param {string} name Employee's name
 * @param {string} initials (optional) Employee's initials. If not passed name is used to create initials. Used as fallback if no avatar provided.
 * @param {string} variant (default: medium) "avatar" | "xs" | "small" | "medium" | "large"
 * @param {string} avatar (optional) URL to avatar (see [Avatar />]{@link ../atoms/Avatar.jsx})
 * @param {string} className (optional) Class names to append to class name list
 * @param {string} subTitle (optional) Used below name in cards "medium" and "large"
 * @param {string} subSubTitle (optional) Used below subTitle in card "medium" and "large"
 * @param {string | ReactComponent} as (default: "div") Element to render as (see [<BaseComponent />]{@link ../atoms/BaseComponent.jsx})
 * @returns ReactNode
 *
 * @example
 *   <EmployeeCard variant="large" name="Cookie Dough" avatar="http://placekitten.com/200/200" subTitle="Supreme kitten" subSubTitle="Demands cuddles" />
 */
export default function EmployeeCard({ variant, ...rest }) {
  const initials = rest.initials ?? getInitials(rest.name);
  switch (variant) {
    case "avatar":
      return <AvatarEmployeeCard {...rest} initials={initials} />;
    case "xs":
      return <ExtraSmallEmployeeCard {...rest} />;
    case "small":
      return <SmallEmployeeCard {...rest} initials={initials} />;
    case "medium":
      return <MediumEmployeeCard {...rest} initials={initials} />;
    case "large":
    default:
      return <LargeEmployeeCard {...rest} initials={initials} />;
  }
}

function getInitials(name) {
  return name.split(" ").reduce((initials, part) => (initials += part[0]), "");
}

export const ExtraSmallEmployeeCard = ({
  as = "div",
  className = "",
  name,
  subTitle,
  subSubTitle,
  ...rest
}) => {
  const innerClassNames = "text-sm";
  const nameParts = name.split(" ");
  return (
    <BaseComponent
      as={as}
      className={`${innerClassNames} ${className}`}
      {...rest}
    >
      {nameParts[0]} {nameParts[nameParts.length - 1][0]}
    </BaseComponent>
  );
};

export const AvatarEmployeeCard = ({
  initials,
  variant,
  name,
  subTitle,
  subSubTitle,
  ...rest
}) => <Avatar variant="small" initials={initials} {...rest} />;

export const SmallEmployeeCard = ({
  as = "div",
  className = "",
  name,
  initials,
  avatar,
  subTitle,
  subSubTitle,
  children,
  ...rest
}) => {
  const innerClassNames =
    "flex flex-row items-center w-full flex-none space-x-3 bg-slate-900 px-6 py-3 text-left focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-900 hover:bg-slate-800 focus:bg-slate-800 focus:outline-none focus:ring-1 focus:ring-slate-900";
  return (
    <BaseComponent
      as={as}
      className={`${innerClassNames} ${className}`}
      {...rest}
    >
      <div className="flex-shrink-0">
        <Avatar variant="small" avatar={avatar} initials={initials} />
      </div>
      <p className="text-sm text-white">{name}</p>
      {children}
    </BaseComponent>
  );
};

export function MediumEmployeeCard({
  as = "div",
  className = "",
  name,
  initials,
  subTitle,
  subSubTitle,
  avatar,
  ...rest
}) {
  const innerClassNames =
    "flex w-full items-center flex-none space-x-3 bg-slate-900 px-6 py-3 text-left focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-900 hover:bg-slate-800 focus:bg-slate-800 focus:outline-none focus:ring-1 focus:ring-slate-900";

  return (
    <BaseComponent
      as={as}
      className={`${innerClassNames} ${className}`}
      {...rest}
    >
      <div className="flex-shrink-0">
        <Avatar avatar={avatar} variant="medium" initials={initials} />
      </div>
      <div className="min-w-0 flex-1">
        <p className="text-sm text-white">{name}</p>
        {subTitle && (
          <p className="truncate text-xs text-slate-400">{subTitle}</p>
        )}
        {subSubTitle && (
          <p className="truncate text-xs text-slate-400">{subSubTitle}</p>
        )}
      </div>
    </BaseComponent>
  );
}

export function LargeEmployeeCard({
  as = "div",
  className = "",
  name,
  initials,
  avatar,
  subTitle,
  subSubTitle,
  variant = "default",
  ...rest
}) {
  const innerClassNames =
    "flex flex-col w-full space-x-3 bg-slate-900 gap-2 px-6 py-3 text-left focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-900 hover:bg-slate-800 focus:bg-slate-800 focus:outline-none focus:ring-1 focus:ring-slate-900";
  // flex w-full flex-none

  // mr-2 flex-shrink-0

  return (
    <BaseComponent
      as={as}
      className={`${innerClassNames} ${className}`}
      {...rest}
    >
      <div className="mx-auto flex-shrink-0">
        <Avatar avatar={avatar} variant="large" initials={initials} />
      </div>
      <div className="min-w-0 flex-1 text-center">
        <p className="text-sm text-white">{name}</p>
        {subTitle && (
          <p className="truncate text-xs text-slate-400">{subTitle}</p>
        )}
        {subSubTitle && (
          <p className="truncate text-xs text-slate-400">{subSubTitle}</p>
        )}
      </div>
    </BaseComponent>
  );
}
