import { createContext, useState } from 'react';

export const SearchContext = createContext(null);

export const SearchProvider = ({ children }) => {
  const [isSearching, setIsSearching] = useState(false);
  return (
    <SearchContext.Provider value={{isSearching, setIsSearching}}>
      {children}
    </SearchContext.Provider>
  );
};
