import { useEffect, useState } from "react";
import { Get } from "../../api/sanity/Get";
import { useSearchContext } from "../search/useSearchContext";
import { Card } from "./Card";
import { useMsal } from "@azure/msal-react";

const Events = () => {
  const [data, setData] = useState([]);
  const { accounts } = useMsal();
  const { isSearching } = useSearchContext();

  const getEvents = async () => {
    const request = '*[_type == "event"] | order(startAt)';
    return await Get(request);
  };

  useEffect(() => {
    getEvents().then((data) => setData(data));
  }, []);

  if (data && !isSearching) {
    return (
      <div className="flex flex-col px-4 py-4">
        <h1 className="mb-4 ml-4 flex-1 text-2xl font-extralight text-slate-100">
          Kalender
        </h1>
        <nav className="grid-fluid-72 grid gap-4">
          {data
            .filter((event) => {
              if (
                Date.parse(Date()) <= Date.parse(event.endAt) &&
                Date.parse(Date()) >= Date.parse(event.publishedAt)
              ) {
                return event;
              } else {
                return null;
              }
            })
            .map((element) => (
              <Card
                key={element._id}
                data={element}
                accountName={accounts[0].name}
              />
            ))}
        </nav>
      </div>
    );
  }

  return null;
};

export default Events;
