import { useContext, useState, useEffect } from "react";
import { TOKEN } from "../../providers/TokenProvider";
import { SendEmail } from "api/microsoft/SendEmail";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { BlockContent } from "components";
import { Get } from "../../api/sanity/Get";

const deviationPostId = "f694d588-f4f9-45e3-97b8-8a7fbd993e04";

const Deviation = () => {
  const context = useContext(TOKEN);
  const [reportMessage, setReportMessage] = useState({
    subject: "",
    description: "",
    sender: "",
  });

  const [data, setData] = useState();

  async function getData() {
    const request = `*[_id == "${deviationPostId}"]`;
    const data = await Get(request);
    setData(data[0]);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div className="flex flex-col space-x-4 p-4 md:flex-row">
      <div className="mb-4 flex w-full flex-col">
        <div className="mx-auto flex w-full max-w-3xl flex-col space-y-4">
          <div className="">
            <p className="text-3xl font-medium text-white">Avviksmelding</p>
            <p className="mt-1 max-w-xl text-sm text-slate-300">
              Avviksmeldingen sendes til avvik@rainfall.no. Skjemaet er er ikke
              anonym da den sendes via din e-postadresse.
            </p>
          </div>
          <div className="pb-1">
            <p className="mb-1 text-slate-200">Tittel</p>
            <input
              className="box-border w-full rounded border border-slate-600 bg-slate-800 p-2 text-white"
              value={reportMessage.subject}
              onChange={(e) =>
                setReportMessage({
                  ...reportMessage,
                  subject: e.target.value,
                })
              }
            />
          </div>
          <div>
            <p className="mb-1 text-slate-200">Beskrivelse</p>
            <textarea
              className="h-60 w-full rounded border border-slate-700 bg-slate-800 text-white  "
              value={reportMessage.description}
              onChange={(e) =>
                setReportMessage({
                  ...reportMessage,
                  description: e.target.value,
                })
              }
            />
          </div>
          <div className="flex w-fit rounded border border-red-400 bg-slate-800 px-3 py-2">
            <ExclamationCircleIcon className="mr-2 h-5 w-5 text-red-400" />
            <p className="text-sm text-red-400">
              Dette skjemaet er ikke anonymt
            </p>
          </div>
          <button
            className="text-lack w-fit rounded bg-indigo-400 px-3 py-1.5 font-medium hover:opacity-80"
            onClick={(e) => SendEmail(context, reportMessage)}
          >
            Send melding
          </button>
        </div>
      </div>
      <div className="w-full">
        <p className="mb-2 text-3xl font-medium text-white">{data.title}</p>
        <div className="text-white">
          <BlockContent value={data.body} />
        </div>
      </div>
    </div>
  );
};

export default Deviation;
