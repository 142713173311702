export default async function getandMergeEmployeeAssignments(token, employees) {
    let assignments;
  
    try {
      assignments = await fetch("https://rainmasterapi.azurewebsites.net/api/Employee/assignments", {headers: {token: "ae09537a-1d2e-4e12-8159-974b2e175fc3"}}).then(res => res.json());
    } catch (e) {
      assignments = {}
    }
  
    return employees.map((employee) => {
      const assignment = assignments.find(assignment => assignment.adId === employee.id);
      if(assignment) {
        employee.assignment = `${assignment?.department}${assignment?.assignment ? ` @ ${assignment.assignment}` : ""}`;
      } else {
        employee.assignment = "Administrasjon";
      }
      return employee
    })
  }