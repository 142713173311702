import { useEffect, useState, useContext } from "react";
import { classNames } from "../../utils/Utils";
import { graphConfig } from "../../auth/Microsoft";
import { Get } from "../../api/microsoft/Get";
import { Delete } from "api/microsoft/Delete";
import { Get as SanityGet } from "../../api/sanity/Get";
import { Post } from "../../api/microsoft/Post";
import { useMsal } from "@azure/msal-react";
import { TOKEN } from "../../providers/TokenProvider";
import { nanoid } from "nanoid";
import sanityClient from "../../auth/Sanity";
import { useLocation } from "react-router-dom";
import { Dialog } from "@headlessui/react";

// Icons
import {
  ChatBubbleOvalLeftEllipsisIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { SendTeamsMessage } from "api/microsoft/SendTeamsMessage";
import { Button, FlexCol, FlexRow } from "components/atoms";
import Submenu from "components/atoms/Submenu";

const Attendees = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState();
  const [data, setData] = useState();
  const [messagePopUp, setMessagePopUp] = useState(false);
  const [teamsMessage, setTeamsMessage] = useState(``);
  const [dialogType, setDialogType] = useState("");
  const { accounts } = useMsal();
  const context = useContext(TOKEN);

  const location = useLocation();

  useEffect(() => {
    getEmployees();
    // eslint-disable-next-line
  }, [context]);

  async function getEmployees() {
    if (context !== "") {
      const data = await Get(context, graphConfig.graphUsersEndpoint);
      setUsers(data);
    }
  }

  async function getPost() {
    const id = location.pathname.split("id/")[1];
    const request = `*[_id == "${id}"]`;
    const data = await SanityGet(request);
    setData(data[0]);
  }

  async function notifyNotAnsweredUsers() {
    const notAnsweredUsers = users.value.filter(
      (user) =>
        !userIsAttending("accepted", user) &&
        !userIsAttending("tentative", user) &&
        !userIsAttending("declined", user)
    );
    await SendTeamsMessage(
      context,
      notAnsweredUsers,
      `${teamsMessage}.<a href='${window.location.href}'>${
        data?.title ?? "Link"
      }</a>`
    );
  }

  async function notifyAttendingUsers() {
    const attendingUsers = users.value.filter((user) =>
      userIsAttending("accepted", user)
    );
    await SendTeamsMessage(
      context,
      attendingUsers,
      `Info til ${data?.title ?? "event"}: ${teamsMessage}`
    );
  }

  async function getEvent(data) {
    const myEventList = await Get(
      context,
      `https://graph.microsoft.com/v1.0/me/events`
    );
    const myEvent = myEventList.value.filter(
      (x) => x.transactionId === data?._id
    );
    return myEvent;
  }

  async function setEventData(data, me) {
    return {
      subject: data?.title,
      start: {
        dateTime: data?.startAt,
        timeZone: "UTC",
      },
      end: {
        dateTime: data?.endAt,
        timeZone: "UTC",
      },
      attendees: [
        {
          emailAddress: {
            address: me?.mail,
            name: me?.displayName,
          },
          type: "required",
        },
      ],
      transactionId: data?._id,
    };
  }

  useEffect(() => {
    getPost();
    // eslint-disable-next-line
  }, []);

  const Registration = async (choice) => {
    setIsLoading(true);
    try {
      const me = await Get(context, graphConfig.graphMeEndpoint);
      const event = await setEventData(data, me);

      for (let i = 0; data?.attendees && i < data?.attendees.length; i++) {
        if (data?.attendees[i].name === accounts[0].name) {
          await updateAttendant(i, choice)
            .then(() => getPost())
            .then(() => setIsLoading(false));

          if (choice === "accepted") {
            let myEvent = await getEvent(data);
            if (myEvent.length === 0) {
              await Post(
                context,
                `https://graph.microsoft.com/v1.0/me/events`,
                event
              );
            }
          }
          if (choice === "declined") {
            const myEvent = await getEvent(data);
            if (myEvent.length > 0) {
              await Delete(
                context,
                `https://graph.microsoft.com/v1.0/me/events/${myEvent[0]?.id}`
              );
            }
          }
          return;
        }
      }
      await addAttendant(choice)
        .then(() => getPost())
        .then(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const addAttendant = async (choice) => {
    if (data)
      return sanityClient
        .patch(data._id)
        .setIfMissing({ attendees: [] })
        .append("attendees", [
          {
            _key: nanoid(),
            choice: choice,
            name: accounts[0].name,
          },
        ])
        .commit()
        .catch(console.error);
  };

  const updateAttendant = async (index, choice) => {
    if (data)
      return sanityClient
        .patch(data._id)
        .insert("replace", `attendees[${index}]`, [
          {
            _key: nanoid(),
            choice: choice,
            name: accounts[0].name,
          },
        ])
        .commit()
        .catch(console.error);
  };

  function userIsAttending(choise, user) {
    return data?.attendees?.find(
      (attendee) =>
        attendee.name === (user ? user.displayName : accounts[0].name) &&
        attendee.choice === choise
    );
  }

  function eventIsFull() {
    return (
      users &&
      users.value.filter((user) => userIsAttending("accepted", user)).length >=
        data.maxAmount
    );
  }

  if (users && data)
    return (
      <div className="">
        <FlexRow className="justify-between">
          <div className="flex pb-3">
            <UserCircleIcon className="my-auto h-4 w-4 text-indigo-400" />
            <p className="my-auto ml-2 text-sm text-slate-200">Attendees</p>
          </div>
          <Submenu
            ToggleButton={(props) => (
              <div className="flex items-center gap-2 text-xs text-slate-400 ">
                <div>Message:</div>
                <Button variant="icon" {...props}>
                  <ChatBubbleOvalLeftEllipsisIcon className="h-4 w-4" />
                </Button>
              </div>
            )}
            title="Send melding"
          >
            <FlexCol className="gap-3 rounded-xl border border-gray-800 bg-gray-900 p-4">
              <div className="text-xs text-gray-400 text-white">
                Send&nbsp;Teams&nbsp;message&nbsp;to:
              </div>
              <Button
                variant="message"
                className="text-sm"
                onClick={() => {
                  setTeamsMessage("");
                  setDialogType("message");
                  setMessagePopUp(!messagePopUp);
                }}
              >
                attending
              </Button>
              <Button
                variant="message"
                className="text-sm"
                onClick={() => {
                  setTeamsMessage("");
                  setDialogType("notify");
                  setMessagePopUp(!messagePopUp);
                }}
              >
                not responded
              </Button>
            </FlexCol>
          </Submenu>
        </FlexRow>

        <div
          className={classNames(
            isLoading ? "animate-pulse" : "",
            "flex w-full"
          )}
        >
          <button
            className={classNames(
              userIsAttending("accepted")
                ? "border-b-green-400 hover:border-b-green-400"
                : "hover:border-b-slate-400",
              "h-auto w-full rounded-l border border-slate-700 bg-slate-800 py-1.5 text-xs text-slate-200 hover:border-slate-600 hover:text-white"
            )}
            onClick={() => !eventIsFull() && Registration("accepted")}
          >
            Accepted
          </button>
          <button
            className={classNames(
              userIsAttending("tentative")
                ? "border-b-yellow-400 hover:border-b-yellow-400"
                : "hover:border-b-slate-400",
              "h-auto w-full border border-slate-700 bg-slate-800 py-1.5 text-xs text-slate-200 hover:border-slate-600 hover:text-white"
            )}
            onClick={() => Registration("tentative")}
          >
            Tentative
          </button>
          <button
            className={classNames(
              userIsAttending("declined")
                ? "border-b-red-400 hover:border-b-red-400"
                : "hover:border-b-slate-400",
              "h-auto w-full rounded-r border border-slate-700 bg-slate-800 py-1.5 text-xs text-slate-200 hover:border-slate-600 hover:text-white"
            )}
            onClick={() => Registration("declined")}
          >
            Declined
          </button>
        </div>
        <div className="">
          <div className="ml-1 flex flex-col pt-4">
            <p className="mb-1 text-sm text-slate-100">{`${
              data?.attendees?.filter(
                (attendee) => attendee.choice === "accepted"
              ).length
            } / ${data.maxAmount ? data.maxAmount : "∞"}`}</p>
            {users.value
              .filter((user) => userIsAttending("accepted", user))
              .sort((p1, p2) =>
                p1.displayName < p2.displayName
                  ? 1
                  : p1.displayName > p2.displayName
                  ? -1
                  : 0
              )
              .reverse()
              .map((user) => (
                <div className="my-1 ml-1 flex h-7" key={user.id}>
                  <div className="my-auto mr-2 flex h-7 w-7 rounded-full bg-green-400">
                    <div className="m-auto flex flex-row">
                      <p className="text-xs text-slate-900">
                        {user?.givenName?.slice(0, 1)}
                      </p>
                      <p className="text-xs text-slate-900">
                        {user?.surname?.slice(0, 1)}
                      </p>
                    </div>
                  </div>
                  <div className="my-auto min-w-0 flex-1">
                    <p className="text-sm text-slate-200">{user.displayName}</p>
                  </div>
                </div>
              ))}
            {users.value
              .filter((user) => userIsAttending("tentative", user))
              .sort((p1, p2) =>
                p1.displayName < p2.displayName
                  ? 1
                  : p1.displayName > p2.displayName
                  ? -1
                  : 0
              )
              .reverse()
              .map((user) => (
                <div className="my-1 ml-1 flex h-7" key={user.id}>
                  <div className="my-auto mr-2 flex h-7 w-7 rounded-full bg-yellow-400">
                    <div className="m-auto flex flex-row">
                      <p className="text-xs text-slate-900">
                        {user?.givenName?.slice(0, 1)}
                      </p>
                      <p className="text-xs text-slate-900">
                        {user?.surname?.slice(0, 1)}
                      </p>
                    </div>
                  </div>
                  <div className="my-auto min-w-0 flex-1">
                    <p className="text-sm text-slate-200">{user.displayName}</p>
                  </div>
                </div>
              ))}
            {users.value
              .filter((user) => userIsAttending("declined", user))
              .sort((p1, p2) =>
                p1.displayName < p2.displayName
                  ? 1
                  : p1.displayName > p2.displayName
                  ? -1
                  : 0
              )
              .reverse()
              .map((user) => (
                <div className="my-1 ml-1 flex h-7" key={user.id}>
                  <div className="my-auto mr-2 flex h-7 w-7 rounded-full bg-red-300">
                    <div className="m-auto flex flex-row">
                      <p className="text-xs text-slate-900">
                        {user?.givenName?.slice(0, 1)}
                      </p>
                      <p className="text-xs text-slate-900">
                        {user?.surname?.slice(0, 1)}
                      </p>
                    </div>
                  </div>
                  <div className="my-auto min-w-0 flex-1">
                    <p className="text-sm text-slate-200">{user.displayName}</p>
                  </div>
                </div>
              ))}
            {users.value
              .filter(
                (user) =>
                  !userIsAttending("accepted", user) &&
                  !userIsAttending("tentative", user) &&
                  !userIsAttending("declined", user)
              )
              .sort((p1, p2) =>
                p1.displayName < p2.displayName
                  ? 1
                  : p1.displayName > p2.displayName
                  ? -1
                  : 0
              )
              .reverse()
              .map((user) => (
                <div className="my-1 ml-1 flex h-7" key={user.id}>
                  <div className="my-auto mr-2 flex h-7 w-7 rounded-full bg-slate-800">
                    <div className="m-auto flex flex-row">
                      <p className="text-xs text-slate-200">
                        {user?.givenName?.slice(0, 1)}
                      </p>
                      <p className="text-xs text-slate-200">
                        {user?.surname?.slice(0, 1)}
                      </p>
                    </div>
                  </div>
                  <div className="my-auto min-w-0 flex-1">
                    <p className="text-sm text-slate-200">{user.displayName}</p>
                  </div>
                </div>
              ))}

            {messagePopUp ? (
              <Dialog
                open={messagePopUp}
                onClose={() => setMessagePopUp(false)}
                className="relative z-50"
              >
                <div className="fixed inset-0 flex w-full items-center justify-center p-4">
                  <Dialog.Panel className="h-fit w-fit max-w-xs rounded-xl border border-slate-600 bg-slate-800 p-4 lg:max-w-2xl">
                    <div className="flex">
                      <label className="text-white">Teams Message</label>
                      <XMarkIcon
                        className="ml-auto h-6 w-6 cursor-pointer text-slate-600 hover:opacity-80"
                        onClick={() => setMessagePopUp(!messagePopUp)}
                      />
                    </div>
                    <p className="mb-2 text-xs text-slate-400">
                      {dialogType === "notify"
                        ? "Send a notification to users who have not responded to the event."
                        : "Send a message to users who have accepted the event."}
                    </p>
                    <div className="flex flex-col">
                      <textarea
                        rows={4}
                        name="teamsMessage"
                        id="teamsMessage"
                        value={teamsMessage}
                        onChange={(e) => setTeamsMessage(`${e.target.value}`)}
                        className="box-border h-20 w-full rounded bg-slate-700 text-white"
                      />
                      <Button
                        variant="submit"
                        type="button"
                        className="mt-4 w-20 rounded bg-blue-600 px-2 py-1 font-medium text-white hover:bg-blue-700"
                        onClick={() => {
                          switch (dialogType) {
                            case "notify":
                              notifyNotAnsweredUsers();
                              break;
                            case "message":
                              notifyAttendingUsers();
                              break;
                            default:
                              return;
                          }
                          setMessagePopUp(!messagePopUp);
                          setDialogType("");
                        }}
                      >
                        Send
                      </Button>
                    </div>
                  </Dialog.Panel>
                </div>
              </Dialog>
            ) : null}
          </div>
        </div>
      </div>
    );

  return <div className=""></div>;
};

export default Attendees;
