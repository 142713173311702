import { useState } from "react";
import {
  H1,
  H2,
  TextArea,
  Button,
  MainSection,
  FlexCol,
  FlexRow,
  Li,
  Paragraph,
} from "../../components/atoms";
import { Get } from "../../api/sanity/Get";
import { useEffect } from "react";
import { BlockContent } from "components";
import { RadioGroup } from "components/molecules";

const noticePostId = "950821e7-4198-49b0-8001-bc92f436d39f";

const sendToOpions = [
  {
    label: "HR-ansvarlig",
    value: "hr",
  },
  {
    label: "Verneombud",
    value: "verneombud",
  },
  {
    label: "Begge",
    value: "both",
  },
];

const Notice = () => {
  const [noticeSent, setNoticeSent] = useState(false);
  const [reportMessage, setReportMessage] = useState({
    description: "",
  });
  const [sendTo, setSendTo] = useState("both");

  const [data, setData] = useState();

  async function getData() {
    const request = `*[_id == "${noticePostId}"]`;
    const data = await Get(request);
    setData(data[0]);
  }

  const NoticeSent = () => {
    return (
      <FlexCol>
        <H2>Ditt varsel er sendt</H2>
        <Paragraph>Ditt varsel er sendt til valgte mottakere.</Paragraph>
        <div>
          <Button variant="submit" onClick={resetForm}>
            Nytt varsel
          </Button>
        </div>
      </FlexCol>
    );
  };

  const resetForm = () => {
    setNoticeSent(false);
    setReportMessage({ description: "" });
    setSendTo("both");
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  async function sendVarsel() {
    setReportMessage("");
    await fetch("https://rainmakers-api.azurewebsites.net/sendEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: reportMessage?.description,
        sendTo: sendTo ?? "both",
      }),
    });
    setNoticeSent(true);
  }

  if (!data) {
    return null;
  }

  return (
    <MainSection>
      <H1>Varsel</H1>
      <FlexRow variant="wrapReverse" className="gap-8 md:gap-12">
        <FlexCol variant="main" className="gap-2">
          {!noticeSent && (
            <>
              <H2 aria-label="notice-textarea">Hendelsesbeskrivelse</H2>
              <TextArea
                id="notice-textarea"
                className="h-96"
                placeholder="Beskrivelse"
                value={reportMessage.description}
                onChange={(e) =>
                  setReportMessage({
                    ...reportMessage,
                    description: e.target.value,
                  })
                }
              />

              <H2>Send til</H2>
              <RadioGroup
                as={FlexRow}
                className="gap-4"
                options={sendToOpions}
                value={sendTo}
                onChange={setSendTo}
              />
              <div>
                <Button variant="submit" onClick={(e) => sendVarsel()}>
                  Send varsel
                </Button>
              </div>
            </>
          )}

          {noticeSent && <NoticeSent />}
        </FlexCol>
        <FlexCol variant="aside" className="gap-2">
          <H2>{data.title}</H2>
          <div className="text-white">
            <BlockContent value={data.body} />
          </div>
          <H2>Varselet bør inneholde</H2>
          <ul>
            <Li>
              Navn og kontaktinformasjon til den som varsler, dersom den som
              varsler ikke ønsker å være anonym
            </Li>
            <Li>
              Hva som har skjedd, eller hva det kritikkverdige forholdet handler
              om
            </Li>
            <Li>Hvem som er involvert</Li>
            <Li>Hvor det skjedde</Li>
            <Li>Om det var vitner til stede</Li>
            <Li>Dokumentasjon eller bevis, dersom dette finnes</Li>
          </ul>
        </FlexCol>
      </FlexRow>
    </MainSection>
  );
};

export default Notice;
