export async function GetBlob(token, endpoint) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers,
  };

  return new Promise((resolve, reject) =>
    fetch(endpoint, options)
      .then((res) => res.blob())
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  );
}
