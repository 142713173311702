import { BaseComponent } from "components";

export default function EmployeeLetterHeader({
  className = "",
  as = "div",
  ...rest
}) {
  return (
    <BaseComponent
      className={`border-y border-slate-800 bg-slate-900 px-6 py-1 text-sm font-medium text-white ${className}`}
      {...rest}
    />
  );
}
