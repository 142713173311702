import { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  ComputerDesktopIcon,
  CalendarIcon,
  BookOpenIcon,
  NewspaperIcon,
  UsersIcon,
  XMarkIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { User } from "../../components";
import { classNames } from "../../utils/Utils";
import { Get } from "../../api/sanity/Get";

const navigation = [
  { name: "Nyheter", href: "/", icon: NewspaperIcon, current: false },
  { name: "Kalender", href: "/calendar", icon: CalendarIcon, current: false },
  {
    name: "Booking",
    href: "/booking",
    icon: ComputerDesktopIcon,
    current: false,
  },
  { name: "Wiki", href: "/wiki", icon: BookOpenIcon, current: false },
  { name: "Ansatte", href: "/employees", icon: UsersIcon, current: false },
];

const Navigation = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [links, setLinks] = useState(null);

  useEffect(() => {
    getLinks();
    // eslint-disable-next-line
  }, []);

  async function getLinks() {
    const request = `*[_type == 'link'] | order(arrangement) {
      title,
      href,
    }`;
    const data = await Get(request);
    setLinks(data);
  }

  if (links)
    return (
      <>
        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </Transition.Child>

              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button
                          type="button"
                          className="-m-2.5 p-2.5"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-slate-900 px-6 pb-4">
                      <div className="flex h-16 shrink-0 items-center">
                        <p className="text-3xl font-thin text-indigo-400">
                          Rainmakers
                        </p>
                      </div>
                      <nav className="flex flex-1 flex-col">
                        <ul className="flex flex-1 flex-col gap-y-7">
                          <li>
                            <ul className="-mx-2 space-y-1">
                              {navigation.map((item) => (
                                <li key={item.name}>
                                  <NavLink
                                    to={item.href}
                                    onClick={() => setSidebarOpen(false)}
                                    className={({ isActive }) =>
                                      isActive
                                        ? "group flex gap-x-3 rounded-md bg-slate-800 p-2 text-sm font-semibold leading-6 text-indigo-400"
                                        : "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-400 hover:bg-slate-800 hover:text-indigo-400"
                                    }
                                  >
                                    <item.icon
                                      className={classNames(
                                        false ? "text-inherit" : "text-inherit",
                                        "h-6 w-6 shrink-0"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li>
                            <div className="text-xs font-semibold leading-6 text-gray-400">
                              External Links
                            </div>
                            <ul className="-mx-2 mt-2 space-y-1">
                              {links.map((link) => (
                                <li key={link.title}>
                                  <a
                                    href={link.href}
                                    className={classNames(
                                      false
                                        ? "bg-slate-800 text-indigo-400"
                                        : "text-slate-400 hover:bg-slate-800 hover:text-indigo-400",
                                      "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        false
                                          ? "border-indigo-600 text-indigo-600"
                                          : "border-slate-400 text-slate-400 group-hover:border-indigo-400 group-hover:text-indigo-400",
                                        "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-slate-700 text-[0.625rem] font-medium"
                                      )}
                                    >
                                      {link.title.slice(0, 1).toUpperCase()}
                                    </span>
                                    <span className="truncate">
                                      {link.title}
                                    </span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-slate-800 bg-slate-900 px-6 pb-4">
              <div className="flex h-16 shrink-0 items-center">
                <p className="text-3xl font-thin text-indigo-400">Rainmakers</p>
              </div>
              <nav className="flex flex-1 flex-col">
                <ul className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul className="-mx-2 space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <NavLink
                            to={item.href}
                            className={({ isActive }) =>
                              isActive
                                ? "group flex gap-x-3 rounded-md bg-slate-800 p-2 text-sm font-semibold leading-6 text-indigo-400"
                                : "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-400 hover:bg-slate-800 hover:text-indigo-400"
                            }
                          >
                            <item.icon
                              className={classNames(
                                false ? "text-inherit" : "text-inherit",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <div className="text-xs font-semibold leading-6 text-gray-400">
                      External Links
                    </div>
                    <ul className="-mx-2 mt-2 space-y-1">
                      {links.map((link) => (
                        <li key={link.title}>
                          <a
                            href={link.href}
                            className={classNames(
                              false
                                ? "bg-slate-800 text-indigo-400"
                                : "text-slate-400 hover:bg-slate-800 hover:text-indigo-400",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <span
                              className={classNames(
                                false
                                  ? "border-indigo-600 text-indigo-600"
                                  : "border-slate-400 text-slate-400 group-hover:border-indigo-400 group-hover:text-indigo-400",
                                "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-slate-700 text-[0.625rem] font-medium"
                              )}
                            >
                              {link.title.slice(0, 1).toUpperCase()}
                            </span>
                            <span className="truncate">{link.title}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className="bg-slate-900 lg:pl-72">
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-slate-800 bg-slate-900 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-slate-600 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div
                className="h-6 w-px bg-slate-800 lg:hidden"
                aria-hidden="true"
              />

              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <div className="ml-auto flex items-center gap-x-4 lg:gap-x-6">
                  <button
                    type="button"
                    className="-m-2.5 hidden p-2.5 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Separator */}
                  <div
                    className="hidden lg:block lg:h-6 lg:w-px lg:bg-slate-800"
                    aria-hidden="true"
                  />

                  {/* Profile dropdown */}
                  <User />
                </div>
              </div>
            </div>

            {/* Main content */}
            <main className="scrollbar-hide">{children}</main>
          </div>
        </div>
      </>
    );
  return null;
};

export default Navigation;
