import { useHits } from 'react-instantsearch';
import { Card } from './Card';

export const Hits = () => {
  const { hits, results } = useHits(null);

  return (
    <div className="px-4 py-4">
      <div className="flex items-baseline gap-8 mb-4 ml-4">
        <h1 className="text-2xl font-extralight text-slate-100 flex-1">
          Wiki
        </h1>
        <h2 className="text-2xl text-slate-100 w-min-lg">
          {hits.length} Treff
        </h2>
        <div className="flex-1"/>
      </div>
      <nav className="grid gap-4 grid-fluid-72">
        {hits.map(hit => (
          <Card key={hit.objectID} data={{ ...hit, _id: hit.objectID }} backgroundColor="bg-cyan-200"/>
        ))}
      </nav>
    </div>
  );
};
