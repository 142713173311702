import { UsersIcon } from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';
import { toDisplayDayMonthYear, toDisplayTime } from '../../utils/Utils';
import { useMsal } from '@azure/msal-react';

const getNumberOfAttendees = (list) => {
  return list && list.filter((item) => item.choice === 'accepted').length;
};

const getUserIconColors = (attendees, accountName) => {
  const attendee = attendees.find((attendee) => attendee.name === accountName);
  if (attendee) {
    switch (attendee.choice) {
      case 'accepted':
        return 'fill-green-600 stroke-green-600';
      case 'declined':
        return 'fill-red-600 stroke-red-600';
      default:
        return '';
    }
  }
  return '';
};

export const Card = ({ data, accountName }) => {
  const { accounts } = useMsal();
  const isSeenByUser = data.seenBy?.some(
    (user) => user.oid === accounts[0].localAccountId
  );

  return (
    <article className="flex h-44 flex-col rounded-3xl bg-yellow-300 hover:opacity-90">
      <NavLink
        to={`/id/${data._id}`}
        className="relative flex h-full flex-col rounded-3xl p-4"
      >
        {!isSeenByUser && (
          <>
            <div className="absolute bottom-4 right-4 h-3 w-3 animate-ping rounded-full bg-red-500" />
            <div className="absolute bottom-4 right-4 h-3 w-3 rounded-full bg-red-500" />
          </>
        )}
        <div className="flex grow flex-col">
          <div className="flex flex-col-reverse justify-end">
            <h3 className="line-clamp-3 text-ellipsis hyphens-auto break-words text-xl text-gray-900">
              {data.title}
            </h3>
            <div className="flex justify-between text-xs text-gray-600">
              <h4>{data.publishedAt && toDisplayDayMonthYear(data.startAt)}</h4>
              {data.toggleAttendees && getNumberOfAttendees(data.attendees) ? (
                <div className="flex flex-row gap-1">
                  <div>{getNumberOfAttendees(data.attendees)}</div>
                  <UsersIcon
                    className={`${getUserIconColors(
                      data.attendees,
                      accountName
                    )} h-4 w-4 cursor-pointer`}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <p className="text-xs text-gray-900">
            {data.startAt && toDisplayTime(data.startAt)}
          </p>
          <p className="text-xs text-gray-900">
            {data.endAt && toDisplayTime(data.endAt)}
          </p>
          <address className="flex grow items-end text-gray-900">
            {data.author || ''}
          </address>
        </div>
      </NavLink>
    </article>
  );
};
