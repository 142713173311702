import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Get } from '../../api/sanity/Get';
import { toDisplayDayMonthYear, toDisplayTime } from '../../utils/Utils';
import { Attendees, BlockContent } from 'components';
import sanityClient from '../../auth/Sanity';
import { useMsal } from '@azure/msal-react';
import { nanoid } from 'nanoid';

function Post() {
  const [data, setData] = useState();
  const location = useLocation();
  const { accounts } = useMsal();

  const navigate = useNavigate();

  async function getData() {
    const id = location.pathname.split('id/')[1];
    const request = `*[_id == "${id}"]`;
    const data = await Get(request);
    setData(data[0]);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const addSeenByUser = async () => {
    const currentUser = {
      name: accounts[0].name,
      oid: accounts[0].localAccountId,
    };

    const isPostAlreadySeen = data.seenBy?.some(
      (user) => user.oid === currentUser.oid
    );

    if (!isPostAlreadySeen) {
      await sanityClient
        .patch(data._id)
        .setIfMissing({ seenBy: [] })
        .append('seenBy', [
          {
            _key: nanoid(),
            name: currentUser.name,
            oid: currentUser.oid,
          },
        ])
        .commit()
        .catch(console.error);
    }
  };

  useEffect(() => {
    if (data) {
      addSeenByUser();
    }
  }, [data]);

  if (data) {
    return (
      <div className="flex h-full w-full flex-col p-4 scrollbar-hide">
        <div className="mx-auto flex max-w-lg flex-col">
          {data._type === 'post' && (
            <p className="mr-4 mt-4 border-b border-slate-800 pb-3 text-xs text-slate-400">
              {data._createdAt && toDisplayDayMonthYear(data._createdAt)}
            </p>
          )}
          {data._type === 'event' && (
            <p className="mr-4 mt-4 border-b border-slate-800 pb-3 text-xs text-slate-400">
              {data._createdAt && toDisplayDayMonthYear(data.startAt)} :{' '}
              {data.startAt && toDisplayTime(data.startAt)} -{' '}
              {data.endAt && toDisplayTime(data.endAt)}
            </p>
          )}
          <p className="mt-2 text-xl text-slate-100">{data.title}</p>
          <p className="mb-4 text-slate-300">{data.author}</p>

          <div className="overflow-scroll scrollbar-hide">
            <article className="prose prose-invert prose-indigo text-white">
              <BlockContent value={data.body} />
            </article>
          </div>
          {data.toggleAttendees && (
            <div className="mt-10 h-full w-80">
              <Attendees />
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
}

export default Post;
