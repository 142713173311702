import { graphConfig } from "../../auth/Microsoft";
import { Get } from "../../api/microsoft/Get";
import { Post } from "../../api/microsoft/Post";

export async function SendEmail(context, message) {
  let answer = window.confirm("Send message?");
  try {
    if (answer) {
      const me = await Get(context, graphConfig.graphMeEndpoint);
      await Post(context, graphConfig.graphSendEmailEndpoint, {
        message: {
          subject: "Avviksmelding",
          body: {
            contentType: "Text",
            content: `Tittel: ${message.subject} 
            \nBeskrivelse: ${message.description}`,
          },
          toRecipients: [
            {
              emailAddress: {
                address: `avvik@rainfall.no`,
              },
            },
          ],
          from: {
            emailAddress: {
              address: `${me?.mail}`,
            },
          },
        },
        saveToSentItems: true,
      });
    }
  } catch (error) {
    console.error(error);
  }
}
