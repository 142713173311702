const getRedirectUriBasedOnNodeEnvironment = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "dev") {
    return "https://lively-beach-08c788303-dev.4.azurestaticapps.net";
  }
  else if (process.env.REACT_APP_ENVIRONMENT === "test") {
    return "https://lively-beach-08c788303-test.4.azurestaticapps.net";
  }
  else if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000/";
  }
  else {
    return "https://rainmakers.no/";
  }
};

export const msalConfig = {
  auth: {
    clientId: "b68072ff-6849-47c6-8281-9f81c9c0f581", // Set microsoft app ID here
    authority:
      "https://login.microsoftonline.com/5ef2f979-7dbd-45a0-9783-b6a94f851a30", // Set microsoft tenant ID here
    redirectUri: getRedirectUriBasedOnNodeEnvironment(),
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

const groupId = "fdd12a21-e643-480e-a904-8408bb1cdd18"; // Set microsoft Group ID here
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMePhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
  graphMeCalendarEventEndpoint: "https://graph.microsoft.com/v1.0/me/events",
  graphUsersEndpoint: `https://graph.microsoft.com/v1.0/groups/${groupId}/members`,
  graphCalendarsEndpoint: `https://graph.microsoft.com/v1.0/groups/${groupId}/events`,
  graphChatEndpoint: `https://graph.microsoft.com/v1.0/chats`,
  graphSendEmailEndpoint: "https://graph.microsoft.com/v1.0/me/sendMail",
  graphUsersPhoto: `https://graph.microsoft.com/v1.0/groups/${groupId}/photo`
};
