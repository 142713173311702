import { useEffect, useState, useContext } from "react";
import EmployeeCard from "./EmployeeCard";
import { TOKEN } from "../../providers/TokenProvider";
export default function ADEmployeeCard({ user, ...rest }) {
  const token = useContext(TOKEN);
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    GetAvatar(token, user.id)
      .then(setAvatar)
      .catch(() =>
        console.log(
          `Shame on ${user.givenName} ${user.surname} for not having an avatar!`
        )
      );
  }, []); //eslint-disable-line

  return (
    <EmployeeCard
      name={user.displayName}
      avatar={avatar}
      subTitle={user.jobTitle}
      subSubTitle={user.assignment !== "" && `${user.assignment}`}
      {...rest}
    />
  );
}
/** GetAvatar
 * Helper for retrieving avatars from Azure AD.
 *
 * @param {*} token AD token
 * @param {*} userid AD userid
 * @returns ObjectUrl
 */
function GetAvatar(token, userid) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append("Authorization", bearer);
  const options = {
    method: "GET",
    headers: headers,
  };

  return new Promise((resolve, reject) =>
    fetch(
      `https://graph.microsoft.com/beta/users/${userid}/photo/$value`,
      options
    )
      .then((response) => {
        if (response.headers.get("content-type") !== "image/jpeg") {
          reject("img not found");
        }

        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => resolve(URL.createObjectURL(blob)))
      .catch((err) => reject(err))
  );
}
