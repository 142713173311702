import { BaseComponent } from "components/atoms";

/** RadioGroup
 * Renders a list of radio buttons.
 * Selected item must be controlled outside component.
 * Wrapper element is a BaseComponent - see BaseComponent for options.
 * 
 * @param as {ReactComponent | string} the element/component to wrap the options with. Defaults to fieldset.
 * @param className {string} class names to pass to the wrapper component.
 * @param options {option[]} [{label: "label for radio button", value: "valOfRadio"}]
 * @param value {string} selected value  
 * @param onChange {(value) => void} Called when selected item should change, i.e on keypress or keyboard interaction
 * @returns ReactNode
 * 
 * @example 
 *   function Container() {
 *     const options = [{label: "one", value: "one"}, {label: "two", value: "two"}];
 *     const [value, setValue] = useState("one");
 *     return <RadioGroup options={options} value={value} onChange={setValue} />
 *   }
 */
export default function RadioGroup({value, options, onChange, ...rest}) {
    const handleOnChange = (e) => {
        onChange(e.target.value);
    }

    return (
      <BaseComponent as="fieldset" {...rest}>
        {options.map((option) => (
          <label className="text-white" key={option.value}>
            {option.label} 
            <input type="radio" className="mx-2" value={option.value} checked={value === option.value} onChange={handleOnChange}/>
          </label>
        ))}
      </BaseComponent>
    ) 
}